import React from "react";
import Figure from "./Figure";
import BasePortableText from "@sanity/block-content-to-react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";
import { CodeBlock } from "./CodeBlock";
import PortableText from "./portableText";

let contentSizes = "w-6/12 xl:w-9/12 mx-auto";
contentSizes = "w-full max-w-3xl xl:max-w-4xl mx-auto";

let BlogParagraph = ({ ...props }) => {
  return (
    <p
      className={`${contentSizes} sm:text-lg leading-extra-relaxed font-light mb-4  text-bodyWhite`}
      {...props}
    />
  );
};

let SectionTitle = ({ children, Tag = "h2", ...props }) => {
  let titleLink = children
    .join(" ")
    .trim()
    .split(" ")
    .join("-");
  let fontSize = "text-3xl xl:text-3xl2 font-display";
  if (Tag === "h4") {
    fontSize = "sm:text-lg font-bold tracking-widest uppercase";
  }
  return (
    <Tag
      className={`${contentSizes} ${fontSize} leading-none  text-normal mb-3 mt-7`}
      id={titleLink}
      {...props}
    >
      {children}
      <a className="inline-flex" href={`#${titleLink}`}>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 54.971 54.971"
          style={{ enableBackground: "new 0 0 54.971 54.971" }}
          className="title-link"
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M51.173,3.801c-5.068-5.068-13.315-5.066-18.384,0l-9.192,9.192c-0.781,0.781-0.781,2.047,0,2.828
		c0.781,0.781,2.047,0.781,2.828,0l9.192-9.192c1.691-1.69,3.951-2.622,6.363-2.622c2.413,0,4.673,0.932,6.364,2.623
		s2.623,3.951,2.623,6.364c0,2.412-0.932,4.672-2.623,6.363L36.325,31.379c-3.51,3.508-9.219,3.508-12.729,0
		c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.048,0,2.828c2.534,2.534,5.863,3.801,9.192,3.801s6.658-1.267,9.192-3.801
		l12.021-12.021c2.447-2.446,3.795-5.711,3.795-9.192C54.968,9.512,53.62,6.248,51.173,3.801z"
            />
            <path
              d="M27.132,40.57l-7.778,7.778c-1.691,1.691-3.951,2.623-6.364,2.623c-2.412,0-4.673-0.932-6.364-2.623
		c-3.509-3.509-3.509-9.219,0-12.728L17.94,24.306c1.691-1.69,3.951-2.622,6.364-2.622c2.412,0,4.672,0.932,6.363,2.622
		c0.781,0.781,2.047,0.781,2.828,0s0.781-2.047,0-2.828c-5.067-5.067-13.314-5.068-18.384,0L3.797,32.793
		c-2.446,2.446-3.794,5.711-3.794,9.192c0,3.48,1.348,6.745,3.795,9.191c2.446,2.447,5.711,3.795,9.191,3.795
		c3.481,0,6.746-1.348,9.192-3.795l7.778-7.778c0.781-0.781,0.781-2.047,0-2.828S27.913,39.789,27.132,40.57z"
            />
          </g>
        </svg>
      </a>
    </Tag>
  );
};

let imageType = ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const fluidProps = getFluidGatsbyImage(node.asset._id, {}, clientConfig.sanity);
  return <ArticleImage fluidProps={fluidProps} alt={node.alt} caption={node.caption} />;
};
let fullContent = `-ml-5 sm:-ml-3  w-full-bleed-2 sm:w-full-bleed `;
let ArticleImage = ({ fluidProps, alt, caption, className, ...props }) => {
  return (
    <div className={`${contentSizes} my-7`}>
      <figure className={cn(fullContent, className)}>
        <Img fluid={fluidProps} alt={alt} className="mb-1" />
        <figcaption className="text-lowWhite mx-auto text-center font-display text-sm">
          {caption}
        </figcaption>
      </figure>
    </div>
  );
};
let ArticleCodeBlock = ({ code, language, ...props }) => {
  return (
    <div className={`${contentSizes}  mx-auto my-7`}>
      <div className={cn(fullContent)}>
        <CodeBlock code={code} language={language} />
      </div>
    </div>
  );
};

let ListSerializer = ({ type, ...props }) => {
  let Tag = type === "bullet" ? "ul" : "ol";
  let listStyle = type === "bullet" ? "list-disc" : "list-decimal";
  return (
    <Tag className={`${contentSizes} list-inside mb-4 xl:text-2lg leading-relaxed pl-1`}>
      {props.children}
    </Tag>
  );
};

let ListItemSerializer = ({ type, ...props }) => {
  let Tag = type === "bullet" ? "ul" : "ol";
  let children =
    !props.node.style || props.node.style === "normal" ? (
      // Don't wrap plain text in paragraphs inside of a list item
      props.children
    ) : (
      // But wrap any other style in whatever the block serializer says to use
      <props.serializers.types.block {...props} />
    );
  return (
    <li className="mb-1 pl-4 relative sm:text-lg leading-relaxed font-light text-bodyWhite">
      <span
        className="absolute left-0 top-0 font-bold text-white text-sm inline-block"
        style={{ lineHeight: "1.9rem" }}
      >
        {props.index}.
      </span>
      {children}
    </li>
  );
};
const serializers = {
  marks: {
    ...BasePortableText.defaultSerializers.marks,
    strong: props => {
      return (
        <strong className=" font-light tracking-normal text-white font-feature-2 font-semibold">
          {props.children}
        </strong>
      );
    },
    inlineCode: props => (
      <span
        className="bg-gray-800 px-2 py-1/2 font-code text-white tracking-wide"
        style={{ fontSize: "0.8em" }}
      >
        {props.children}
      </span>
    ),
    link: props => {
      return (
        <a
          href={props.mark.href}
          target={props.mark.href.substring(0, 1) === "#" ? "" : "_blank"}
          className=" border-b-1 border-gray-200 text-white font-display tracking-tiny"
        >
          {props.children}
        </a>
      );
    }
  },
  list: ListSerializer,
  listItem: ListItemSerializer,
  types: {
    lineBreak: () => <br />,
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: node => {
      return imageType(node);
    },
    codesandboxEmbed: props => {
      return (
        <div className={`${contentSizes} mb-4`}>
          <iframe
            src={props.node.href + ""}
            style={{
              width: "100%",
              height: "300px",
              border: "0",
              borderRadius: "4px",
              overflow: "hidden"
            }}
            sandbox="allow-scripts allow-same-origin"
            className={props.node.caption ? "mb-1" : ""}
          />
          {!props.node.caption ? null : (
            <p className="text-lowWhite mx-auto text-center font-display text-sm">
              <PortableText
                blocks={[{ ...props.node.caption[0], _key: "test", _type: "block", style: null }]}
              />
            </p>
          )}
        </div>
      );
    },
    code: props => {
      return <ArticleCodeBlock code={props.node.code} language={props.node.language} />;
    },
    block: props => {
      // Don't give it a default to allow for inline blocks
      const style = props.node.style;

      if (style === "blockquote") {
        return (
          <blockquote className={`${contentSizes} py-2 pl-4 border-l-4 border-white `}>
            {props.children}
          </blockquote>
        );
      }

      if (/^h\d/.test(style)) {
        let TitleTag = style;
        return <SectionTitle Tag={TitleTag}>{props.children} </SectionTitle>;
      }

      if (style === "centered" || style === "centeredSmall") {
        let textSize =
          style === "centeredSmall"
            ? "text-sm sm:text-base text-lowWhite"
            : "text-2lg xl:text-xl font-display  text-highWhite";
        return (
          <p className={`${contentSizes} ${textSize} leading-normal text-center centered-block`}>
            {props.children}
          </p>
        );
      }

      if (style === "note") {
        return (
          <p
            className={`${contentSizes} mt-2 mb-4 relative border-1 px-5 py-4 font-display text-normal`}
          >
            <span
              className=" font-body font-bold text-xs bg-white text-darkBlue absolute top-0 uppercase tracking-widest leading-relaxed px-3 py-1/2"
              style={{ transform: "translateY(-70%)", left: "20px" }}
            >
              note
            </span>
            {props.children}
          </p>
        );
      }

      if (style == null) {
        return <>{props.children}</>;
      }

      return style === "blockquote" ? (
        <blockquote> {props.children}</blockquote>
      ) : (
        <BlogParagraph>{props.children}</BlogParagraph>
      );

      // return BasePortableText.defaultSerializers.types.block(props);
    }
  }
};

export default serializers;
