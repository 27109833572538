import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import "../styles/layout.css";

let linkClasses = "pr-4 last:pr-0";

let NavLink = ({ children, className, ...props }) => {
  return (
    <li className="pr-6 last:pr-0 font-display font-normal text-xs sm:text-base tracking-wide">
      <Link className={"block no-underline hover:text-gray-600"} {...props}>
        {children}
      </Link>
    </li>
  );
};

// <button
//   className="apperance-none border-none bg-transparent m-0 outline-none hidden p-1"
//   onClick={showNav ? onHideNav : onShowNav}
// >
//   <Icon symbol="hamburger" className="block fill-current" />
// </button>;

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className="relative z-50">
    <div className="w-full flex flex-row p-3 xs:px-6 py-4 sm:pt-6 md:p-7">
      <div className="w-1/3 uppercase font-display font-normal flex-1 leading-none">
        <Link
          to="/"
          className="hover:text-gray-600 no-underline tracking-widest text-xs sm:text-base"
        >
          {siteTitle}
        </Link>
      </div>

      <ul className="w-2/3 m-0 px-0 sm:list-none flex justify-end items-center ">
        <NavLink to="/blog/">Articles</NavLink>
        <NavLink to="/about/">About</NavLink>
      </ul>
    </div>
  </div>
);

const Layout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  className = "",
  contentClassName = "",
  screen
}) => (
  <div
    className={cn(
      className,
      "max-w-desktop mx-auto",
      screen ? "h-screen overflow-hidden flex flex-col" : ""
    )}
  >
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={cn(contentClassName, "w-full px-5 sm:px-7", screen ? "h-full " : "")}>
      {children}
    </div>
    <footer className="pb-4 py-6 px-4 xs:px-6 sm:pb-6 md:px-7  w-12">
      <div className="w-full mx-auto flex flex-row justify-between font-display text-xs">
        <h5 className="">Daniel Velasquez &copy; {new Date().getFullYear()}</h5>
        <ul className="list-none flex">
          <li className={linkClasses}>
            <a href="mailto:velasquezdanield@gmail.com" target="_blank">
              Email
            </a>
          </li>
          <li className={linkClasses}>
            <a href="https://twitter.com/Anemolito" target="_blank">
              Twitter
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
);

export default Layout;
